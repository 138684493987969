import React from 'react';
import Logo from '../../components/browntree-green-circle.png';
import './index.scss';
import Layout from "../../components/Layout";

const SuccessIndex = () => (
  <Layout>
    <div className="bg-light-orange success__page text-center pb-4 pt-4">
      <div className="alert alert-success" role="alert">
        Thank you for filling out the form!
      </div>
      <div className="container text-center">
        <a href="/">
          <img src={Logo} alt="" className="home-logo"/>
        </a>
        <h1 className="mb-4">We will get back to you shortly</h1>
        <p className="text-secondary h4 mb-4">
          You can expect to hear back within one business day. We look
          forward to working with you and can't wait to get started!
        </p>
      </div>
    </div>
  </Layout>
);

export default SuccessIndex;
